import React from 'react';
import { Box, VStack, HStack, Heading, Text, Flex, Image, Button, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import lightcone_logo from './lightcone_cover.jpg';

const Data = () => {
  return (
    <Box className="App">
      <Box
        as="nav"
        padding="1.5rem"
        bg="black"
        color="white"
      >
        <Flex align="center" justify="space-between" wrap="wrap">
          <Flex align="center" mr={5}>
          <Link to="/">
              <Image src={lightcone_logo} alt="Lightcone Logo" maxHeight="75px" />
            </Link>
          </Flex>
          <Spacer />
          <HStack spacing={4}>
            <Link to="/about">
                <Button 
                  bg="black" 
                  color="rgba(0, 200, 255, 0.7)" 
                  border="2px solid rgba(0, 200, 255, 0.7)"
                  _hover={{ 
                    bg: "black", 
                    color: "rgba(255, 105, 180, 0.7)",
                    border: "2px solid rgba(255, 105, 180, 0.7)"
                  }}
                >
                  About
                </Button>
            </Link>
            <Link to="/contact">
                <Button 
                  bg="black" 
                  color="rgba(0, 200, 255, 0.7)" 
                  border="2px solid rgba(0, 200, 255, 0.7)"
                  _hover={{ 
                    bg: "black", 
                    color: "rgba(255, 105, 180, 0.7)",
                    border: "2px solid rgba(255, 105, 180, 0.7)"
                  }}
                >
                  Contact Us
                </Button>
            </Link>
            <Link to="/data">  
              <Button 
                  bg="black" 
                  color="rgba(0, 200, 255, 0.7)" 
                  border="2px solid rgba(0, 200, 255, 0.7)"
                  _hover={{ 
                    bg: "black", 
                    color: "rgba(255, 105, 180, 0.7)",
                    border: "2px solid rgba(255, 105, 180, 0.7)"
                  }}
                >
                Research
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
      <main style={{ backgroundColor: 'black', color: 'white', padding: '40px 0', minHeight: 'calc(100vh - 200px)' }}>
        <VStack spacing={6} align="center" mt={8}>
          <Heading as="h1" size="xl" color="rgba(0, 200, 255, 0.7)">
            Crypto Market Outlook: Year End 2024
          </Heading>
          <Text fontSize="xl" textAlign="center" maxW="600px">
            Coming Soon
          </Text>
        </VStack>
      </main>
      <footer style={{ backgroundColor: 'black', color: 'white', padding: '20px 0' }}>
        <HStack spacing={4} justify="center" mt={8} mb={4}>
          <Text color="white">Miami, FL, USA</Text>
        </HStack>
        <Text textAlign="center" fontSize="sm" color="gray.500">
          &copy; {new Date().getFullYear()} Lightcone Trading LLC
        </Text>
      </footer>
    </Box>
  );
};

export default Data;

{/* 
  Original component code commented out:

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseBTC = await fetch('http://127.0.0.1:5000/api/crypto/BTC-USD');
        const btcData = await responseBTC.json();
        console.log('BTC Data:', btcData);

        const responseETH = await fetch('http://127.0.0.1:5000/api/crypto/ETH-USD');
        const ethData = await responseETH.json();
        console.log('ETH Data:', ethData);

        const responseSOL = await fetch('http://127.0.0.1:5000/api/crypto/SOL-USD');
        const solData = await responseSOL.json();
        console.log('SOL Data:', solData);

        setData({
          BTC: btcData,
          ETH: ethData,
          SOL: solData
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderTable = (cryptoData, name) => {
    if (!cryptoData || !cryptoData.daily_return_stats || !cryptoData.daily_volatility_stats) {
      return <Text key={name}>No data available for {name}</Text>;
    }

    return (
      <Box key={name}>
        <Heading size="md">{name}</Heading>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Metric</Th>
              <Th>Daily Return</Th>
              <Th>Daily Volatility</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Mean</Td>
              <Td>{cryptoData.daily_return_stats.mean?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.mean?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Median</Td>
              <Td>{cryptoData.daily_return_stats.median?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.median?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>High</Td>
              <Td>{cryptoData.daily_return_stats.high?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.high?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Low</Td>
              <Td>{cryptoData.daily_return_stats.low?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.low?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Standard Deviation</Td>
              <Td>{cryptoData.daily_return_stats.std?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.std?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Today's Value</Td>
              <Td>{cryptoData.daily_return_stats.today?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.today?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Std Devs from Mean</Td>
              <Td>{cryptoData.daily_return_stats.std_devs_from_mean?.toFixed(4)}</Td>
              <Td>{cryptoData.daily_volatility_stats.std_devs_from_mean?.toFixed(4)}</Td>
            </Tr>
            <Tr>
              <Td>Trailing Annual Volatility</Td>
              <Td colSpan="2">{cryptoData.daily_volatility_stats.annual_volatility?.toFixed(4)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    );
  };

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error.message}</Text>;

  return (
    <Box className="App">
      <Box
        as="nav"
        padding="1.5rem"
        bg="linear-gradient(0deg, rgba(155,155,155,155) 0%, rgba(255,255,255,1) 100%)"
      >
        <Flex align="center" justify="space-between" wrap="wrap">
          <Flex align="center" mr={5}>
            <Image src={lightcone_logo} alt="Lightcone Logo" maxHeight="50px" />
          </Flex>
          <Spacer />
          <HStack spacing={4}>
            <Link to="/">
              <Button colorScheme="blackAlpha">About</Button>
            </Link>
            <Link to="/contact">
              <Button colorScheme="blackAlpha">Contact Us</Button>
            </Link>
            <Link to="/data">
              <Button colorScheme="blackAlpha">Data</Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
      <Box padding="2rem">
        <VStack spacing={8} align="center">
          <Heading as="h1">Crypto Data</Heading>
          {data.BTC && renderTable(data.BTC, 'BTC-USD')}
          {data.ETH && renderTable(data.ETH, 'ETH-USD')}
          {data.SOL && renderTable(data.SOL, 'SOL-USD')}
        </VStack>
      </Box>
    </Box>
  );
*/}
